<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/approve/coach' }">教练申请列表</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form v-loading="loading" size="medium"
                             :label-width="this.env.label_width">
                        <el-form-item label="状态">
                            <el-tag size="mini" v-if="info.state===0" type="info">未审核</el-tag>
                            <el-tag size="mini" v-if="info.state===1" type="success">
                                通过{{ info.audit_time }}
                            </el-tag>
                            <el-tag size="mini" v-if="info.state===2" type="danger">
                                拒绝{{ info.audit_time }}
                            </el-tag>
                        </el-form-item>
                        <el-form-item label="昵称">
                            <el-image
                                    style="width: 22px; height: 22px; margin-right: 4px;"
                                    :src="info.avatar_url"
                                    fit="contain"
                                    :preview-src-list="[info.avatar_url]">
                            </el-image>
                            <span class="form-span-text">{{ info.user_nick }}</span>
                        </el-form-item>
                        <el-form-item label="手机号">
                            <span class="form-span-text">{{ info.phone }}</span>
                        </el-form-item>
                        <el-form-item label="用户姓名">
                            <span class="form-span-text">{{ info.actual_name }}</span>
                        </el-form-item>
                        <el-form-item label="资格证书">
                            <el-image
                                    style="width: 160px; height: 120px; margin-right: 4px;"
                                    :src="info.certificate_photo"
                                    fit="contain"
                                    :preview-src-list="[info.certificate_photo]">
                            </el-image>
                        </el-form-item>
                        <el-form-item label="身份证照片">
                            <el-image
                                    style="width: 160px; height: 120px; margin-right: 4px;"
                                    :src="info.card_front"
                                    fit="contain"
                                    :preview-src-list="[info.card_front]">
                            </el-image>
                            <el-image
                                    style="width: 160px; height: 120px; margin-right: 4px;"
                                    :src="info.card_back"
                                    fit="contain"
                                    :preview-src-list="[info.card_back]">
                            </el-image>
                        </el-form-item>
                        <el-form-item v-if="!is_empty(info.audit_remark)" label="审批备注">
                            <el-card class="box-card">
                                <span class="form-span-text">{{ info.audit_remark }}</span>
                            </el-card>
                        </el-form-item>

                        <el-form-item>
                            <el-button size="medium" @click="isreturn">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>

export default {
    components: {},
    data() {
        return {
            loading: false,
            certified_uuid: '',
            page_name: '教练详情',
            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let certified_uuid = this.$route.query.certified_uuid
            if (certified_uuid !== undefined) {
                this.certified_uuid = certified_uuid
                this.getinfo()
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({path: '/approve/talent'})    // 返回列表
                    }
                });
            }
        },
        // 获取教练详情信息
        getinfo() {
            let postdata = {
                api_name: "approve.coach.getinfo",
                token: this.Tool.get_l_cache('token'),
                certified_uuid: this.certified_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.info = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
